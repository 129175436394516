import './App.css';
import { Routes, Route } from "react-router-dom";
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Pages/Home';
import AZYouth_Nov from './Pages/Event__pages/AZYouth_Nov.js';
import Founder from './Pages/Founder';
import PastEvents from './Pages/PastEvents';
import Upcoming from './Pages/Upcoming';
import AZYouth_Feb from './Pages/Event__pages/AZYouth_Feb';
import AZYouth_Mar from './Pages/Event__pages/AZYouth_Mar';
import AZYouth_Apr from './Pages/Event__pages/AZYouth_Apr';

function App() {
  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Past-Events" element={<PastEvents />} />
        <Route path="/Meet-The-Founder" element={<Founder />} />
        <Route path="/Upcoming-Events" element={<Upcoming />} />
        <Route path="/events/AZYR_11-2021" element={<AZYouth_Nov />} />
        <Route path="/events/AZYR_02-2022" element={<AZYouth_Feb />} />
        <Route path="/events/AZYR_03-2022" element={<AZYouth_Mar />} />
        <Route path="/events/AZYR_04-2022" element={<AZYouth_Apr />} />
      </Routes>

      <Footer />
    </div>
  );
}
export default App;
