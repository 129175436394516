const November = [
    { image: '../ImageAssets/event__image00.JPG' },
    { image: '../ImageAssets/event__image01.JPG' },
    { image: '../ImageAssets/event__image02.JPG' },
    { image: '../ImageAssets/event__image03.JPG' },
    { image: '../ImageAssets/event__image04.JPG' },
    { image: '../ImageAssets/event__image05.JPG' },
    { image: '../ImageAssets/event__image06.JPG' },
    { image: '../ImageAssets/event__image07.JPG' },
    { image: '../ImageAssets/event__image08.JPG' },
    { image: '../ImageAssets/event__image09.JPG' },
    { image: '../ImageAssets/event__image10.JPG' },
    { image: '../ImageAssets/event__image11.JPG' },
    { image: '../ImageAssets/event__image12.JPG' },
    { image: '../ImageAssets/event__image13.JPG' },
    { image: '../ImageAssets/event__image14.JPG' },
    { image: '../ImageAssets/event__image15.JPG' },
    { image: '../ImageAssets/event__image16.JPG' },
    { image: '../ImageAssets/event__image17.JPG' },
    { image: '../ImageAssets/event__image18.JPG' },
    { image: '../ImageAssets/event__image19.JPG' },
    { image: '../ImageAssets/event__image20.JPG' },
]

const March = [
    { image: '../ImageAssets/_MG_0243.JPG' },
    { image: '../ImageAssets/_MG_0273.JPG' },
    { image: '../ImageAssets/_MG_0251.JPG' },
    { image: '../ImageAssets/_MG_0369.JPG' },
    { image: '../ImageAssets/_MG_0208.JPG' },
    { image: '../ImageAssets/_MG_0239.JPG' },
    { image: '../ImageAssets/_MG_0209.JPG' },
    { image: '../ImageAssets/_MG_0221.JPG' },
    { image: '../ImageAssets/_MG_0353.JPG' },
    { image: '../ImageAssets/_MG_0274.JPG' },
    { image: '../ImageAssets/_MG_0371.JPG' },
    { image: '../ImageAssets/_MG_0248.JPG' },
]

export { November, March }